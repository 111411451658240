import React from 'react';
import { FormattedMessage, Link, useIntl } from 'gatsby-plugin-intl';
import { Footer, Header } from '@app/components/template';
import blogBack from '@app/images/blog-back.jpg';
import { Seo } from '@app/components';

export default () => {
  const intl = useIntl();
  return (
    <>
      <Seo title={ intl.formatMessage({ id: 'seo_title_regulations' }) }
           description={ intl.formatMessage({ id: 'seo_description_second' }) } />
      <div className="main-content-wraper privacy-page">
        <div className="main-content-inner Post">
          <Header />

          <section className="breadcrumb-section"
                   style={ {
                     backgroundImage: `url(${ blogBack })`,
                     backgroundSize: 'cover',
                     backgroundPosition: 'center top',
                     backgroundRepeat: 'no-repeat'
                   } }>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="text-center breadcrumb-content">
                    <h2><FormattedMessage id="footer_regulations" /></h2>
                    <ul className="breadcrumb">
                      <li>
                        <Link to='/'>
                          <FormattedMessage id="footer_home" />
                        </Link>
                      </li>
                      <li>
                        <FormattedMessage id="footer_regulations" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="blog-details-area bg-secondary ptb-60">
            <div className="blog-details-area-wrap">
              <div className="container">
                <div className="row">
                  <div className="col-md-8 col-md-offset-2">
                    <div className="blog-single-content-wrap">
                      <h6 className="privacy-page__list-header">§1 Postanowienia Ogólne</h6>
                      <ol className="privacy-page__main-list">
                        <li>
                          Niniejszy regulamin („<strong>Regulamin</strong>”) określa zasady korzystania z aplikacji
                          webowej „Karta Zgłoszeń” wspierającej organizację turniejów tanecznych
                          („<strong>Aplikacja</strong>”). Pozostałe terminy użyte w Regulaminie oznaczają:
                          <ol type="a">
                            <li><strong>Konto</strong> – konto prowadzone w Aplikacji zakładane poprzez udostępniony
                              formularz;
                            </li>
                            <li><strong>Okres Gwarancyjny</strong> – okres 14 dni od dnia zakończenia pierwszego
                              turnieju organizowanego przy pomocy Aplikacji przez Organizatora, w ciągu którego
                              Organizator może od Umowy odstąpić i otrzymać pełen zwrot opłat;
                            </li>
                            <li><strong>Organizator</strong> – osoba fizyczna prowadząca działalność gospodarczą, osoba prawna lub jednostka organizacyjna nieposiadająca osobowości prawnej organizująca turniej tańca;
                            </li>
                            <li><strong>Serwis</strong> – strona internetowa <Link to="/">www.kartazgloszen.pl</Link>;
                            </li>
                            <li><strong>Umowa</strong> – umowa o odpłatne korzystanie z funkcjonalności Aplikacji
                              umożliwiających w szczególności organizację turnieju tanecznego zawierana między
                              Organizatorem a Administratorem na podstawie Regulaminu;
                            </li>
                            <li><strong>Użytkownik</strong> – osoba fizyczna, osoba prawna lub jednostka organizacyjna
                              nieposiadająca osobowości prawnej niebędąca Organizatorem, w szczególności tancerze lub
                              szkoły tańca.
                            </li>
                          </ol>
                        </li>
                        <li>Przed założeniem Konta Organizator oraz Użytkownik zobowiązani są do zapoznania się z
                          treścią Regulaminu. Zakładając Konto Organizator oraz Użytkownik akceptują treść Regulaminu.
                        </li>
                        <li>Właścicielem i administratorem Aplikacji jest Paweł Radomski prowadzący działalność
                          gospodarczą pod firmą „Setenta Paweł Radomski”, ze stałym miejscem wykonywania działalności
                          gospodarczej w Domaszczynie (55-095) pod adresem: ul. Przylesie 76, zarejestrowaną w
                          Centralnej Ewidencji i Informacji o Działalności Gospodarczej, NIP 6472489879, REGON 362711060
                          („<strong>Administrator</strong>”) z którym można skontaktować się pod numerem telefonu (+48)
                          793 432 042 lub za pomocą adresu e-mail: <a
                            href="mailto:kontakt@kartazgloszen.pl">kontakt@kartazgloszen.pl</a>.
                        </li>
                        <li>Regulamin określa zasady świadczenia przez Administratora usług drogą elektroniczną w
                          postaci prowadzenia Konta oraz umożliwienia korzystania z Aplikacji oraz przechowywania
                          wprowadzanych do Aplikacji danych i stanowi regulamin świadczenia usług drogą elektroniczną w
                          rozumieniu art. 8 ust. 1 pkt 1 Ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą
                          elektroniczną (tekst jedn. Dz.U. z 2020 r., poz. 344).
                        </li>
                        <li>Informacje znajdujące się w Serwisie nie stanowią oferty w rozumieniu art. 66 § 1 Kodeksu
                          Cywilnego. Ogłoszenia, reklamy, cenniki i inne informacje podane w Serwisie stanowią jedynie
                          zaproszenie do zawarcia umowy zgodnie z art. 71 Kodeksu Cywilnego.
                        </li>
                      </ol>

                      <h6 className="privacy-page__list-header">§ 2. Warunki korzystania z aplikacji</h6>
                      <ol className="privacy-page__main-list">
                        <li>Użytkownik oraz Organizator mogą korzystać z Aplikacji za pośrednictwem urządzeń końcowych z
                          dostępem do Internetu, w tym komputera, laptopa, urządzeń mobilnych. Do korzystania z
                          Aplikacji zalecane jest korzystanie z najnowszych wersji przeglądarek internetowych Mozilla
                          Firefox lub Google Chrome.
                        </li>
                        <li>Aplikacja działa w trybie online, co oznacza, że do korzystania z wszystkich funkcjonalności
                          Aplikacji konieczne jest utrzymywanie w czasie korzystania z Aplikacji stałego dostępu do
                          sieci Internet.
                        </li>
                        <li>Administrator zastrzega sobie możliwość regularnej aktualizacji Aplikacji i jej
                          funkcjonalności.
                        </li>
                        <li>Zabronione jest dostarczanie przez Użytkowników oraz Organizatorów w związku z korzystaniem
                          z Aplikacji treści o charakterze bezprawnym, a w szczególności naruszających prawa własności
                          intelektualnej, dobra osobiste lub dobre obyczaje, a także naruszające przepisy dotyczące
                          ochrony danych osobowych.
                        </li>
                        <li>Autorzy zamieszczanych w Aplikacji utworów graficznych oraz treści nie wyrażają zgody na ich
                          jakąkolwiek publikację lub modyfikację w jakikolwiek sposób (w wydawnictwach, publikacjach,
                          prezentacjach, stronach internetowych i w inny sposób ujęty w ustawie o ochronie praw
                          autorskich) bez ich uprzedniej, pisemnej zgody.
                        </li>
                        <li>Zabronione jest ingerowanie przez Użytkownika oraz Organizatora w Aplikację lub Serwis, a
                          także podejmowanie działań mogących skutkować zagrożeniem dla stabilności Aplikacji lub
                          Serwisu.
                        </li>
                        <li>Zabronione jest udostępnianie swoich danych do logowania w Aplikacji jakimkolwiek osobom
                          trzecim.
                        </li>
                      </ol>

                      <h6 className="privacy-page__list-header">§ 3. Konto użytkownika</h6>
                      <ol className="privacy-page__main-list">
                        <li>W celu korzystania z Aplikacji przez Użytkownika, w szczególności w celu dokonywania
                          zgłoszeń tancerzy na turnieje taneczne organizowane przez Organizatorów za pośrednictwem
                          Aplikacji konieczne jest założenie Konta oraz wprowadzenie danych do faktury w ustawieniach
                          Konta.
                        </li>
                        <li>Utworzenie Konta wiąże się z zawarciem pomiędzy Użytkownikiem a Administratorem umowy na
                          korzystanie z Aplikacji i prowadzenie Konta. Utworzenie Konta jest dobrowolne i bezpłatne i
                          odbywa się w ramach formularza internetowego dostępnego w Serwisie poprzez podanie imienia,
                          nazwiska, nazwy klubu, miejscowości, telefonu oraz adresu e-mail. Użytkownik uzyskuje dostęp
                          do Konta po potwierdzeniu założenia Konta poprzez kliknięcie linka otrzymanego w wiadomości
                          e-mail i zalogowanie w Serwisie.
                        </li>
                        <li>Użytkownik zobowiązany jest do zachowania w tajemnicy haseł dostępu do Konta. Wszelkie
                          czynności dokonane przez jakąkolwiek osobę trzecią z wykorzystaniem hasła dostępu do Konta
                          traktuje się jako czynności wykonane przez Użytkownika, na którego ono wskazuje.
                        </li>
                        <li>Użytkownik w każdej chwili może zmienić swoje dane w Aplikacji korzystając z odpowiednich
                          opcji dostępnych po zalogowaniu w zakładce Moje Konto.
                        </li>
                        <li>Użytkownik może rozwiązać umowę na korzystanie z Aplikacji i prowadzenie Konta poprzez
                          usunięcie Konta za pomocą wyboru opcji Usuń Konto dostępnej w ustawieniach konta w Aplikacji.
                        </li>
                        <li>Administrator ma prawo do zamknięcia Konta Użytkownika z jednomiesięcznym okresem
                          wypowiedzenia, z zastrzeżeniem postanowień §5 ust. 3 Regulaminu.
                        </li>
                      </ol>

                      <h6 className="privacy-page__list-header">§ 4. Organizacja turnieju, funkcjonalności dla
                        organizatora</h6>
                      <ol className="privacy-page__main-list">
                        <li>
                          W celu zawarcia Umowy i skorzystania z funkcjonalności Aplikacji przeznaczonych dla
                          Organizatora niezbędne jest uprzednie założenie bezpłatnego Konta Użytkownika w Serwisie na
                          zasadach określonych w §3 Regulaminu oraz uzupełnienie danych do faktury w ustawieniach Konta
                          w Aplikacji. Następnie, po utworzeniu Konta Organizator ma możliwość utworzenia turnieju
                          tanecznego:
                          <ol type="a">
                            <li>przy użyciu wydzielonego w Aplikacji Panelu („<strong>Panel Organizatora</strong>”)
                              poprzez wprowadzenie założeń turnieju tanecznego, w tym zasad zapisów na turniej, jeśli
                              opcja ta jest dostępna w Aplikacji, lub
                            </li>
                            <li>poprzez ręczną konfigurację Panelu Organizatora i dodanie założeń turnieju we współpracy
                              z Administratorem.
                            </li>
                          </ol>
                        </li>
                        <li>Po utworzeniu turnieju i określeniu jego zasad Organizator ma możliwość uiszczenia opłaty i
                          zawarcia Umowy.
                        </li>
                        <li>Umowa zostaje zawarta na czas określony związany z organizacją konkretnego turnieju, za
                          który uiszczona została opłata, w momencie zaksięgowania tej opłaty. Opłatę uiszcza się w
                          wysokości wynikającej z wybranego pakietu (zgodnie z cennikiem) przelewem na wskazany przez
                          Administratora numer rachunku bankowego lub poprzez płatność natychmiastową realizowaną za
                          pośrednictwem zewnętrznego operatora płatności internetowych, jeśli opcja ta jest dostępna w
                          Aplikacji. Wraz z zaksięgowaniem opłaty Organizator ma możliwość udostępnienia turnieju w
                          Aplikacji w taki sposób, że staje się on widoczny dla innych Użytkowników i uruchomienia
                          zapisów na ten turniej.
                        </li>
                        <li>Umowa zostaje zawarta w związku z danym turniejem i wygasa po zakończeniu tego turnieju, za
                          który uiszczona została opłata. Po zakończeniu turnieju Organizator nadal ma dostęp do danych
                          zebranych w Aplikacji zgromadzonych na potrzeby turnieju (o ile dane nie zostaną usunięte z
                          uwagi na obowiązujące przepisy prawa, w tym m.in. dotyczące ochrony dany osobowych) z tym
                          zastrzeżeniem, że wszelkie utwory muzyczne zamieszczone przez Użytkowników w Aplikacji na
                          potrzeby danego turnieju tańca będą przechowywane przez okres 7 dni od dnia zakończeniu tego
                          turnieju, a po tym czasie mogą zostać usunięte.
                        </li>
                        <li>Zawarcie Umowy nie wyklucza możliwości zawierania dalszych Umów dotyczących organizacji
                          kolejnych turniejów tanecznych lub umowy na korzystanie z Aplikacji i z prowadzenia Konta
                          Użytkownika w celu dokonywania zapisów na turnieje.
                        </li>
                        <li>W przypadku jeśli Organizator nie jest zadowolony z funkcjonowania Aplikacji, ma prawo do
                          odstąpienia od Umowy w Okresie Gwarancyjnym. Informację o odstąpieniu od Umowy Organizator
                          powinien przesłać na adres e-mail: <a
                            href="mailto:kontakt@kartazgloszen.pl">kontakt@kartazgloszen.pl</a>. W tym wypadku
                          Administrator zwróci na rzecz Organizatora wpłaconą opłatę za wybrany pakiet w terminie 30 dni
                          od dnia otrzymania przez Administratora informacji o odstąpieniu. Organizator ma prawo do
                          odstąpienia od Umowy w Okresie Gwarancyjnym wyłącznie jeden raz, co do pierwszego turnieju
                          tanecznego organizowanego z użyciem Aplikacji.
                        </li>
                        <li>Organizator ma prawo rozwiązania Umowy najpóźniej w terminie do 30 dni przed planowaną datą
                          turnieju, którego dotyczy zawarta Umowa. W przypadku rozwiązania Umowy na podstawie
                          niniejszego postanowienia Organizatorowi nie przysługuje zwrot uiszczonej opłaty.
                        </li>
                      </ol>

                      <h6 className="privacy-page__list-header">§ 5. Działanie aplikacji, odpowiedzialność
                        administratora</h6>
                      <ol className="privacy-page__main-list">
                        <li>Administrator ma prawo do dokonywania aktualizacji, napraw, usunięcia błędów oraz prac
                          konserwacyjnych nad Aplikacją, które mogą spowodować ograniczenia możliwości korzystania z
                          Aplikacji. Administrator niezwłocznie poinformuje Organizatora o planowanych zmianach, które
                          mogą zakłócić dostęp do Aplikacji (za pomocą wiadomości e-mail lub poprzez umieszczenie
                          informacji w Serwisie lub Aplikacji), o ile jest to możliwe. Utrudnienie lub uniemożliwienie
                          korzystania z Aplikacji w przypadku, gdy Organizator został o nich poinformowany nie uznaje
                          się za nienależyte wykonanie Umowy.
                        </li>
                        <li>Górna granica odpowiedzialności Administratora z tytułu niewykonania lub nienależytego
                          wykonania Umowy oraz z tytułu deliktu jest ograniczona do dwukrotnej wysokości ceny jednego
                          pakietu uiszczonej przez Organizatora.
                        </li>
                        <li>Administrator ma prawo do uniemożliwienia dostępu do Aplikacji lub niektórych jej
                          funkcjonalności, usunięcia Konta, a także do rozwiązania Umowy w trybie natychmiastowym, bez
                          prawa do żądania odszkodowania, w przypadku naruszania przez Organizatora lub Użytkownika
                          postanowień § 2 ust. 4-7 Regulaminu, podejmowania działań naruszających bezpieczeństwo
                          Aplikacji, podejmowania działań mogących spowodować awarię Aplikacji oraz nieuprawnionego
                          udostępniania danych do logowania do Konta.
                        </li>
                      </ol>

                      <h6 className="privacy-page__list-header">§ 6. Reklamacje</h6>
                      <ol className="privacy-page__main-list">
                        <li>Wszelkie reklamacje dotyczące Aplikacji należy kierować do Administratora na adres e-mail:
                          <a href="mailto:kontakt@kartazgloszen.pl">kontakt@kartazgloszen.pl</a> lub pocztą na adres
                          Administratora wskazany w § 1 Regulaminu.
                        </li>
                        <li>W reklamacji należy podać dane Użytkownika oraz opis zgłaszanych nieprawidłowości.</li>
                        <li>Reklamacje będą rozpatrywane w terminie 14 dni od dnia wpływu reklamacji.</li>
                      </ol>

                      <h6 className="privacy-page__list-header">§ 6. Postanowienia końcowe</h6>
                      <ol className="privacy-page__main-list">
                        <li>Administrator zastrzega sobie prawo do zmiany niniejszego Regulaminu w dowolnym czasie.
                          Informacja o zmianie Regulaminu zostanie przesłana Użytkownikom i Organizatorom na podane
                          przez nich w Aplikacji adresy e-mail lub udostępniona w widoczny sposób w Aplikacji nie
                          później niż na 14 (słownie: czternaście) dni przed wprowadzeniem zmienionego Regulaminu.
                        </li>
                        <li>
                          W przypadku braku akceptacji zmienionego Regulaminu:
                          <ol type="a">
                            <li>Użytkownikowi przysługuje prawo rozwiązania umowy na korzystanie z Aplikacji i
                              prowadzenie Konta poprzez usunięcie Konta;
                            </li>
                            <li>Organizatorowi przysługuje prawo do wypowiedzenia Umowy ze skutkiem na koniec okresu
                              rozliczeniowego, za który została już dokonana opłata abonamentowa.
                            </li>
                          </ol>
                        </li>
                        <li>Użytkownik oraz Organizator wyrażają zgodę na otrzymywanie faktur drogą elektroniczną na
                          adres e-mail podany w Aplikacji.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Footer />
        </div>
      </div>
    </>
  );
}
